import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  ContentCard,
  Card,
  ButtonLink,
  Tag as ComponentTag,
} from '@teo/components';
import { useNavigate } from 'react-router-dom';

function ListOfPublications({ courses, basePath, type }: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function convertToTime(number: number) {
    const hours = Math.floor(number / 3600);
    const minutes = Math.round((number / 3600 - hours) * 60);
    return (
      hours +
      `${t('pages.learn_index.hour')} ` +
      minutes +
      `${t('pages.learn_index.minute')} `
    );
  }

  const createMarkup = (text: any) => {
    if (text) return { __html: text };
  };

  return (
    <>
      {courses?.length > 0 && (
        <div className="my-4 flex items-center justify-between">
          <h1 className="text-2xl font-bold text-black">
            {t(`courseTypes.${type}`)}
          </h1>
          <ButtonLink to={`${basePath}`} variant="ghost">
            {t('pages.create_index.see_all') +
              ' ' +
              t(`courseTypes.${type}`).toLowerCase()}
          </ButtonLink>
        </div>
      )}
      <div className="flex flex-wrap justify-start md:flex-wrap lg:flex-nowrap">
        <div className="mt-3 flex w-full flex-row flex-wrap gap-3 lg:flex-nowrap">
          {courses.map((course: any) => (
            <div
              className="relative max-w-[calc(50%_-_8px)] basis-1/2 gap-4 sm:max-w-[calc(33.3333%_-_8px)] sm:basis-1/3 lg:max-w-none lg:basis-1/4"
              key={course.id}
            >
              <ContentCard
                customStyles="text-left whitespace-break-spaces w-full overflow-y-hidden text-ellipsis"
                chapters={course?.nrOfLessons ? course.nrOfLessons : 0}
                duration={
                  course?.totalEstimatedTime
                    ? convertToTime(Math.abs(course?.totalEstimatedTime))
                    : ''
                }
                title={course?.name ? course?.name : ''}
                onClick={() =>
                  navigate(`${basePath}/${course.coursecontentId}`)
                }
                Image={() => (
                  <ContentCard.Image
                    src={
                      course?.image && !course?.image.indexOf('http')
                        ? course.image
                        : '/assets/images/opleidingen.jpg'
                    }
                    alt="Electrical dial"
                  />
                )}
                Tag={() => (
                  <div className="mb-3 text-left">
                    <ComponentTag title={course.publicationStatus || ''} />
                  </div>
                )}
                Actions={() => (
                  <>
                    <Card.Button
                      onClick={() =>
                        navigate(`${basePath}/${course.coursecontentId}`)
                      }
                    >
                      {t('pages.create_index.to_process')}
                    </Card.Button>
                    <div className="absolute top-0">
                      <img
                        key={course.id}
                        src={`/images/flags/${course.lang}.svg`}
                        alt="courseImage"
                        className="
                      mt-2
                      ml-2
                      h-[25px]
                      w-[25px] rounded-[50%]
                      bg-tint-red
                      object-cover
                      "
                      />
                    </div>
                  </>
                )}
                authors={[
                  {
                    id: `${course.authorId}`,
                    imageSrc: `${course.picture}`,
                    name: `${course?.username}`,
                  },
                ]}
              >
                <div className="mb-3 max-h-[60px] overflow-hidden text-ellipsis text-left text-sm font-normal text-[#6d6d72]">
                  <div
                    dangerouslySetInnerHTML={createMarkup(course?.description)}
                  />
                </div>
              </ContentCard>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default ListOfPublications;
