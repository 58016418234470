import { useContext, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MoreHorizontal,
  CrossFilled,
  Options,
  DuoToneCourseOutline,
  Chapter,
  Document,
  Pencil,
  ArrowLeft,
  Share,
  DuoToneAccountFilled,
} from '@teo/components/icons';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { format } from 'date-fns';
import {
  IconButton,
  Header,
  Dropdown,
  Button,
  Tag,
  AvatarStack,
  PersonAvatar,
  Avatar,
  Spinner,
} from '@teo/components';
import { langData } from '@/mocks/landData';
import { axios } from '@/axios';
import { config } from '@/config';
import { nthIndexOf } from '@/util';
import { Userwithorganisationwithidps } from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';
import i18n from 'i18next';
import Hint from '@/components/Hints/Hints';
import { HintsContext } from '@/components/Contexts/HintsContext';
import { useGet } from '@/query';
import { OrganisationContext } from '@/components/Contexts/OrganisationContext';
import clsx from 'clsx';

const EditCourseHeader = ({
  publication,
  setPublication,
  allPublicationCourses,
  setIsOpenChangeLang,
  setIsOpenInviteModal,
  setIsOpenVersionModal,
  contentCreators,
  setIsOpenPublishSettings,
  setIsOpenExportModal,
  setIsOpenPublish,
  activeNode,
  setActiveNode,
  coursecontentId,
  setMobileSidebar,
  mobileSidebar,
  onNavigate,
  setLesQueryParams,
  setIsOpenShareSettings,
  newPage,
  isOwner,
}: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { hints } = useContext(HintsContext);
  const { auth, setAuth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;
  const userRole = auth?.user?.role || 0;
  const isScorm = publication?.publicationType?.startsWith?.('scorm') || false;

  const { myOrganisations } = useContext(OrganisationContext);

  let isAdminSomewhereElse = false,
    adminOrganisations = [];
  try {
    adminOrganisations = myOrganisations.filter(
      (org: any) =>
        org.id !== auth?.user?.activeOrganisationId && org.role >= 90
    );
    isAdminSomewhereElse = adminOrganisations.length > 0;
  } catch (e) {
    console.error(e);
  }

  const isDesktop = useMediaQuery({
    query: '(min-width: 1280px)',
  });

  const isTablet = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const basePath = window.location.pathname.slice(
    0,
    nthIndexOf(window.location.pathname, '/', 3)
  );

  let courseType = 'courses';
  if (publication?.publicationCourseType === 'assessment') {
    courseType = `assessments`;
  } else {
    if (publication?.publicationCourseType === 'leervacature') {
      courseType = `vacancies`;
    } else {
      courseType = `courses`;
    }
  }

  const [duplicatePublication, setDuplicatePublication] = useState();
  const [isDuplicate, setIsDuplicate] = useState();
  const [preloader, setPreloader] = useState(false);

  const handleDuplicate = async () => {
    await onNavigate();
    const data = await axios.post(
      `${config.backend}/publish/copy/${publication?.id}`,
      { organisationId: authUser?.activeOrganisationId }
    );
    await axios.put(`${config.backend}/coursecontent/${data?.data?.id}`, {
      publicationStatus: 'concept',
      name: publication?.name + ` (${t('pages.create_edit_course.duplicate')})`,
    });
    await axios.put(
      `${config.backend}/membercoursecontent/${authUser?.activeOrganisationId}/${data?.data?.id}`,
      {
        role: 90,
      }
    );
    setPreloader(false);
    await navigate(
      `/${i18n.language}/create/${authUser?.activeOrganisationId}/${courseType}/${data?.data?.id}`
    );
    navigate(0);
  };

  useEffect(() => {
    (async () => {
      if (isDuplicate) {
        setPreloader(false);
        await onNavigate();
        navigate(`${basePath}/${courseType}/${isDuplicate}`);
      }
    })();
  }, [isDuplicate]);

  const [isDelete, setIsDelete] = useState<any>();

  const handleDelete = async () => {
    const del = await axios.post(
      `${config.backend}/coursecontentdata/detach/${coursecontentId}`,
      {}
    );
    setIsDelete(del);
  };
  useEffect(() => {
    (async () => {
      if (isDelete?.data) {
        await onNavigate();
        navigate(basePath);
      }
    })();
  }, [isDelete]);

  const handleActivate = () => {
    setPublication((prev: any) => ({
      ...prev,
      publicationStatus: 'concept',
    }));
    axios.put(`${config.backend}/coursecontent/${publication?.id}`, {
      id: publication?.id,
      publicationStatus: 'concept',
    });
  };

  const handleDeactivate = async () => {
    if (allPublicationCourses) {
      const promises: any = [];
      allPublicationCourses.map((el: any) => {
        promises.push(
          axios.put(`${config.backend}/course/${el?.id}`, {
            courseStatus: 'disabled',
          })
        );
      });
      Promise.allSettled(promises).then(async () => {
        setPublication((prev: any) => ({
          ...prev,
          publicationStatus: 'disabled',
        }));
        await axios.put(`${config.backend}/coursecontent/${publication?.id}`, {
          id: publication?.id,
          publicationStatus: 'disabled',
        });
      });
    }
  };

  const LangOptionComponent = () => {
    return (
      <>
        {preloader && (
          <div className="fixed inset-0 z-[11] flex bg-white/75">
            <div className="m-auto">
              <Spinner
                ariaLabel="Loading spinner"
                className="!h-16 !w-16 border-grey-08"
              />
            </div>
          </div>
        )}
        {!isScorm && (
          <div
            className={`relative mx-0 lg:mx-5 ${
              hints?.id == 'hint_32' &&
              'active-hint-create-page active-hint-parent active-hint-main-menu !z-[100]'
            }`}
            onClick={() => {
              setIsOpenChangeLang(true);
            }}
          >
            <Hint
              key={`hint_32_${newPage}`}
              id={'hint_32'}
              leftDots={'-left-1 top-3'}
              title={t('hint.title_32')}
              order={32}
              style={{
                left: '2px',
                top: '76px',
              }}
            />
            {langData.find(
              (data) => data.value === publication?.lang && data.flag
            ) ? (
              <Button variant="ghost" className="!px-2.5">
                <img
                  src={`/images/flags/${publication.lang}.svg`}
                  alt="courseImage"
                  className="
                      h-[32px] w-[32px] min-w-[32px]
                      rounded-[50%]
                      object-cover
                    "
                />
              </Button>
            ) : (
              <PersonAvatar
                id={publication?.id}
                name={publication?.name as string}
                imageSrc={`/images/flags/${publication?.lang}.svg` || undefined}
              />
            )}
          </div>
        )}
        {isScorm && (
          <div className="mx-0 lg:mx-5">
            <PersonAvatar
              id={publication?.id}
              name={publication?.name as string}
              imageSrc={`/images/flags/${publication?.lang}.svg` || undefined}
            />
          </div>
        )}
        <div
          className={`group relative mr-3 ${
            hints?.id == 'hint_33' &&
            'active-hint-create-page active-hint-parent active-hint-main-menu !z-[100]'
          }`}
        >
          <Hint
            key={`hint_33_${newPage}`}
            id={'hint_33'}
            leftDots={'-left-1.5 top-1.5'}
            title={t('hint.title_33')}
            order={33}
            style={{
              left: '2px',
              top: '70px',
            }}
          />
          <IconButton
            Icon={Options}
            variant="ghost"
            className="text-[#7d7d81]"
            onClick={() => setIsOpenPublishSettings(true)}
            disabled={!isOwner}
          />
          {!isOwner && (
            <div className="absolute right-0 top-[110%] z-[10] hidden w-[300px] rounded-lg bg-white p-3 text-xs text-grey-08 shadow-[0_0_5px_-1px_rgba(0,0,0,0.3)] group-hover:flex">
              {t('pages.create_edit_course.message_not_owner')}
            </div>
          )}
        </div>

        {/*(userRole >= 90 || isAdminSomewhereElse) && (
          <div className={`relative `}>
            <IconButton
              Icon={Share}
              variant="ghost"
              className="text-[#7d7d81]"
              onClick={() => setIsOpenShareSettings(true)}
            />
          </div>
        )*/}
      </>
    );
  };
  const PrsonUpadteComponent = () => {
    const avatars = [];
    avatars.push(
      ...(contentCreators || [])
        .map((item: any) => (
          <PersonAvatar
            key={`user_${item.id}`}
            name={item.username || ''}
            imageSrc={item?.picture ? item?.picture : ''}
            id={item.id}
            //key={item.id}
          />
        ))
        .slice(0, 3)
    );
    avatars.push(
      <Avatar key="icon_ket" className={clsx(' bg-grey-02 text-black')}>
        <div className="flex h-8 min-h-8 w-8 min-w-8 items-center justify-center">
          <Share className=""></Share>
        </div>
      </Avatar>
    );

    return (
      <>
        <Button
          size="sm"
          variant="ghost"
          className={`relative text-xs font-normal !text-[#919193] xl:text-center ${
            hints?.id == 'hint_34' &&
            'active-hint-parent active-hint-main-menu !z-[100]'
          } `}
          onClick={() => setIsOpenVersionModal(true)}
        >
          <Hint
            key={`hint_34_${newPage}`}
            id={'hint_34'}
            leftDots={'-left-2 top-1.5'}
            title={t('hint.title_34')}
            order={34}
            style={{
              right: '2px',
              top: '65px',
            }}
          />
          {t('pages.create_edit_course.latest_update')}
          {format(new Date(publication.updatedAt || ''), 'dd/MM/yyyy')}
        </Button>
        <div
          className={`group relative mt-4 xl:mx-6 xl:mt-0 ${
            hints?.id == 'hint_35' &&
            'active-hint-create-page active-hint-parent active-hint-main-menu !z-[100]'
          }`}
        >
          {!isOwner && hints?.id !== 'hint_35' && (
            <div className="absolute right-0 top-[110%] z-[10] hidden w-[300px] rounded-lg bg-white p-3 text-xs text-grey-08 shadow-[0_0_5px_-1px_rgba(0,0,0,0.3)] group-hover:flex">
              {t('pages.create_edit_course.message_not_publish')}
            </div>
          )}
          <Hint
            key={`hint_35_${newPage}`}
            id={'hint_35'}
            leftDots={'-left-2 top-[10px]'}
            title={t('hint.title_35')}
            order={35}
            style={{
              right: '2px',
              top: '70px',
            }}
          />
          {contentCreators?.length > 0 ? (
            <div
              role="button"
              className={`rounded-lg p-1 pl-1 ${
                !isOwner && 'pointer-events-none'
              }`}
            >
              <AvatarStack
                maxAvatars={4}
                onClick={() => {
                  setIsOpenInviteModal(true);
                }}
              >
                {avatars}
              </AvatarStack>
            </div>
          ) : (
            <IconButton
              Icon={DuoToneAccountFilled}
              ariaLabel="Search"
              className="!rounded-full"
              variant="outline"
              onClick={() => {
                setIsOpenInviteModal(true);
              }}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <div>
      {!isMobile ? (
        <Header
          imgSrc="/images/TEO_logo.svg"
          navigation={
            <div className="flex items-center">
              <IconButton
                className="my-auto mx-2"
                Icon={CrossFilled}
                variant="ghost"
                onClick={async () => {
                  await onNavigate();
                  navigate(basePath);
                }}
              />
              <DuoToneCourseOutline className="h-6 w-6 text-[#7d7d81]" />

              <div className="mx-5 text-base font-medium line-clamp-2">
                {publication.name}
              </div>

              <div className="mr-4 lg:mr-0">
                {publication.publicationStatus === 'published' ? (
                  <Tag
                    title={publication.publicationStatus}
                    size="md"
                    variant="primary"
                  />
                ) : (
                  <Tag title={publication.publicationStatus || ''} size="md" />
                )}
              </div>
              {isScorm && (
                <Tag
                  className="ml-2"
                  variant="aqua_light"
                  title={t('pages.create_edit_course.imported')}
                  size="md"
                />
              )}
              {isTablet && <LangOptionComponent />}
            </div>
          }
          rightComponent={
            <div className="flex items-center">
              {isDesktop && <PrsonUpadteComponent />}
              {!isScorm && (
                <div className="relative mr-3">
                  <Dropdown>
                    <Dropdown.Trigger
                      className={`${
                        hints?.id == 'hint_36' &&
                        'active-hint-parent active-hint-main-menu !z-[100]'
                      }`}
                    >
                      <Hint
                        key={`hint_36_${newPage}`}
                        id={'hint_36'}
                        leftDots={'-left-2 top-[7px]'}
                        title={t('hint.title_36')}
                        order={36}
                        style={{
                          right: '2px',
                          top: '71px',
                        }}
                      />
                      <p className="rounded-md border border-grey-03 px-4 py-2 text-center text-sm font-medium text-grey-09 transition-colors duration-200 ease-out hover:bg-grey-transparent-01">
                        {t('pages.create_edit_course.example_button')}
                      </p>
                    </Dropdown.Trigger>
                    <Dropdown.Content>
                      <Dropdown.Actions>
                        <Dropdown.Button
                          onClick={async () => {
                            await onNavigate();
                            navigate(
                              `${basePath}/${courseType}/${
                                publication?.id
                              }/preview?les=${
                                activeNode?.data?.id
                                  ? activeNode?.data?.id
                                  : activeNode?.data?.assetId
                                  ? activeNode?.data?.assetId
                                  : 0
                              }&view=mobile`
                            );
                          }}
                          className="disabled:pointer-events-none disabled:text-grey-04"
                          disabled={
                            activeNode?.droppable ||
                            activeNode?.data?.rootDirectoryId ||
                            activeNode?.data?.rootLessonId
                          }
                        >
                          <p>{t('pages.create_edit_course.view_mobile')}</p>
                        </Dropdown.Button>

                        <Dropdown.Button
                          onClick={async () => {
                            await onNavigate();
                            navigate(
                              `${basePath}/${courseType}/${
                                publication?.id
                              }/preview?les=${
                                activeNode?.data?.id
                                  ? activeNode?.data?.id
                                  : activeNode?.data?.assetId
                                  ? activeNode?.data?.assetId
                                  : 0
                              }`
                            );
                          }}
                          className="disabled:pointer-events-none disabled:text-grey-04"
                          disabled={
                            activeNode?.droppable ||
                            activeNode?.data?.rootDirectoryId ||
                            activeNode?.data?.rootLessonId
                          }
                        >
                          <p>{t('pages.create_edit_course.view_desktop')}</p>
                        </Dropdown.Button>
                      </Dropdown.Actions>
                    </Dropdown.Content>
                  </Dropdown>
                </div>
              )}
              <div
                className={`group relative ${
                  hints?.id == 'hint_37' &&
                  'active-hint-parent active-hint-main-menu !z-[100]'
                }`}
              >
                <Button
                  disabled={
                    (!isOwner &&
                      publication?.publicationAccess !== 'protected') ||
                    publication?.publicationStatus === 'disabled'
                  }
                  onClick={async () => {
                    await onNavigate();
                    setIsOpenPublish(true);
                  }}
                  size="md"
                >
                  {t('pages.create_edit_course.publish_button')}
                </Button>
                {!isOwner &&
                  hints?.id !== 'hint_37' &&
                  publication?.publicationAccess !== 'protected' && (
                    <div className="absolute right-0 top-[110%] z-[10] hidden w-[300px] rounded-lg bg-white p-3 text-xs text-grey-08 shadow-[0_0_5px_-1px_rgba(0,0,0,0.3)] group-hover:flex">
                      {t('pages.create_edit_course.message_not_publish')}
                    </div>
                  )}
                <Hint
                  key={`hint_37_${newPage}`}
                  id={'hint_37'}
                  leftDots={'-left-2 top-[7px]'}
                  title={t('hint.title_37')}
                  order={37}
                  style={{
                    right: '2px',
                    top: '70px',
                  }}
                />
              </div>
              <div className="relative z-[11] ml-3">
                <Dropdown>
                  <Dropdown.Trigger
                    className={`${
                      hints?.id == 'hint_38' &&
                      'active-hint-parent active-hint-main-menu'
                    }`}
                  >
                    <Hint
                      key={`hint_38_${newPage}`}
                      id={'hint_38'}
                      leftDots={'-left-2 top-1.5'}
                      title={t('hint.title_38')}
                      order={38}
                      style={{
                        right: '2px',
                        top: '70px',
                      }}
                    />
                    <IconButton Icon={MoreHorizontal} variant="ghost" />
                  </Dropdown.Trigger>
                  <Dropdown.Content>
                    {!isTablet && (
                      <Dropdown.Section>
                        <div className="flex flex-wrap justify-between">
                          <LangOptionComponent />
                        </div>
                      </Dropdown.Section>
                    )}
                    {!isDesktop && (
                      <Dropdown.Section>
                        <PrsonUpadteComponent />
                      </Dropdown.Section>
                    )}

                    <Dropdown.Actions>
                      <Dropdown.Button
                        onClick={() =>
                          isOwner && setIsOpenPublishSettings(true)
                        }
                        disabled={!isOwner}
                        className={`group relative ${
                          !isOwner && 'text-grey-04'
                        }`}
                      >
                        {t('pages.create_edit_course.settings_button')}
                        {!isOwner && (
                          <div className="absolute right-0 top-[110%] z-[10] hidden w-[300px] rounded-lg bg-white p-3 text-xs text-grey-08 shadow-[0_0_5px_-1px_rgba(0,0,0,0.3)] group-hover:flex">
                            {t('pages.create_edit_course.message_not_owner')}
                          </div>
                        )}
                      </Dropdown.Button>

                      <Dropdown.Button
                        onClick={() => {
                          setPreloader(true);
                          handleDuplicate();
                        }}
                      >
                        {t('pages.create_edit_course.duplicate_button')}
                      </Dropdown.Button>

                      {!isScorm && (
                        <Dropdown.Button
                          onClick={() => {
                            setIsOpenChangeLang(true);
                          }}
                        >
                          {t('pages.create_edit_course.translate_button')}
                        </Dropdown.Button>
                      )}

                      {userRole >= 90 && (
                        <Dropdown.Button
                          onClick={() => setIsOpenShareSettings(true)}
                        >
                          {t('pages.create_edit_course.share_button')}
                        </Dropdown.Button>
                      )}

                      {!isScorm && (
                        <Dropdown.Button
                          onClick={() => {
                            setIsOpenExportModal(true);
                          }}
                        >
                          {t('pages.create_edit_course.export')}
                        </Dropdown.Button>
                      )}

                      {publication?.publicationStatus === 'disabled' ? (
                        <Dropdown.Button
                          onClick={() => isOwner && handleActivate()}
                          disabled={!isOwner}
                          className={`group relative ${
                            !isOwner && 'text-grey-04'
                          }`}
                        >
                          {t('pages.create_edit_course.activate_button')}
                          {!isOwner && (
                            <div className="absolute right-0 top-[110%] z-[10] hidden w-[300px] rounded-lg bg-white p-3 text-xs text-grey-08 shadow-[0_0_5px_-1px_rgba(0,0,0,0.3)] group-hover:flex">
                              {t('pages.create_edit_course.message_not_owner')}
                            </div>
                          )}
                        </Dropdown.Button>
                      ) : (
                        <Dropdown.Button
                          onClick={() => isOwner && handleDeactivate()}
                          disabled={!isOwner}
                          className={`group relative ${
                            !isOwner && 'text-grey-04'
                          }`}
                        >
                          {t('pages.create_edit_course.deactivate_button')}
                          {!isOwner && (
                            <div className="absolute right-0 top-[110%] z-[10] hidden w-[300px] rounded-lg bg-white p-3 text-xs text-grey-08 shadow-[0_0_5px_-1px_rgba(0,0,0,0.3)] group-hover:flex">
                              {t('pages.create_edit_course.message_not_owner')}
                            </div>
                          )}
                        </Dropdown.Button>
                      )}

                      <Dropdown.Button
                        onClick={() => isOwner && handleDelete()}
                        disabled={!isOwner}
                        className={`group relative text-[#FC6232] ${
                          !isOwner && ' text-grey-04'
                        }`}
                      >
                        {t('pages.create_edit_course.delete_button')}
                        {!isOwner && (
                          <div className="absolute right-0 top-[110%] z-[10] hidden w-[300px] rounded-lg bg-white p-3 text-xs text-grey-08 shadow-[0_0_5px_-1px_rgba(0,0,0,0.3)] group-hover:flex">
                            {t('pages.create_edit_course.message_not_owner')}
                          </div>
                        )}
                      </Dropdown.Button>
                    </Dropdown.Actions>
                  </Dropdown.Content>
                </Dropdown>
              </div>
            </div>
          }
        ></Header>
      ) : (
        <header
          className={`flex flex-col border-b border-solid border-grey-02 bg-white py-3`}
        >
          {!mobileSidebar && isMobile ? (
            <div className="flex items-center justify-start px-3">
              <nav className="w-full">
                <div className="flex items-center">
                  <IconButton
                    className="my-auto mr-auto"
                    Icon={ArrowLeft}
                    variant="ghost"
                    onClick={() => {
                      setActiveNode(null);
                      setLesQueryParams(null);
                      setMobileSidebar(true);
                    }}
                  />
                  {activeNode?.droppable || false ? (
                    <Chapter className="m-2 w-6 min-w-6" />
                  ) : (
                    <Document className="m-2 w-6 min-w-6" />
                  )}
                  <p className="w-full flex-1 text-sm font-normal	text-[#231f20] ">
                    {activeNode?.text}
                  </p>
                  <Pencil className="mx-2 w-5 min-w-5" />
                </div>
              </nav>
            </div>
          ) : (
            <>
              <div className="flex items-center justify-start px-3">
                <img src="/images/TEO_logo.svg" className="mr-4" />
                <nav className="w-full">
                  <div className="flex items-center">
                    <IconButton
                      className="my-auto mr-auto"
                      Icon={CrossFilled}
                      variant="ghost"
                      onClick={async () => {
                        await onNavigate();
                        navigate(-1);
                      }}
                    />
                    <div className="mr-3">
                      <Button
                        onClick={async () => {
                          await onNavigate();
                          navigate(
                            `${basePath}/${courseType}/${publication?.id}/preview`
                          );
                        }}
                        size="sm"
                        variant="outline"
                      >
                        {t('pages.create_edit_course.example_button')}
                      </Button>
                    </div>
                    <div className="group relative">
                      <Button
                        onClick={() => setIsOpenPublish(true)}
                        size="sm"
                        disabled={
                          (!isOwner &&
                            publication?.publicationAccess !== 'protected') ||
                          publication?.publicationStatus === 'disabled'
                        }
                      >
                        {t('pages.create_edit_course.publish_button')}
                      </Button>
                      {!isOwner &&
                        hints?.id !== 'hint_37' &&
                        publication?.publicationAccess !== 'protected' && (
                          <div className="absolute right-0 top-[110%] z-[10] hidden w-[300px] rounded-lg bg-white p-3 text-xs text-grey-08 shadow-[0_0_5px_-1px_rgba(0,0,0,0.3)] group-hover:flex">
                            {t('pages.create_edit_course.message_not_publish')}
                          </div>
                        )}
                    </div>
                    <div className="ml-3">
                      <Dropdown>
                        <Dropdown.Trigger>
                          <IconButton Icon={MoreHorizontal} variant="ghost" />
                        </Dropdown.Trigger>
                        <Dropdown.Content>
                          {!isTablet && (
                            <Dropdown.Section>
                              <div className="flex flex-wrap justify-between">
                                <LangOptionComponent />
                              </div>
                            </Dropdown.Section>
                          )}
                          {!isDesktop && (
                            <Dropdown.Section>
                              <PrsonUpadteComponent />
                            </Dropdown.Section>
                          )}
                          {/* <Dropdown.Actions> */}
                          <Dropdown.Button
                            onClick={() =>
                              isOwner && setIsOpenPublishSettings(true)
                            }
                            disabled={!isOwner}
                            className={`group relative ${
                              !isOwner && 'text-grey-04'
                            }`}
                          >
                            {t('pages.create_edit_course.settings_button')}
                            {!isOwner && (
                              <div className="absolute right-0 top-[110%] z-[10] hidden w-[300px] rounded-lg bg-white p-3 text-xs text-grey-08 shadow-[0_0_5px_-1px_rgba(0,0,0,0.3)] group-hover:flex">
                                {t(
                                  'pages.create_edit_course.message_not_owner'
                                )}
                              </div>
                            )}
                          </Dropdown.Button>
                          <Dropdown.Button
                            onClick={() => {
                              setPreloader(true);
                              handleDuplicate();
                            }}
                          >
                            {t('pages.create_edit_course.duplicate_button')}
                          </Dropdown.Button>
                          <Dropdown.Button
                            onClick={() => {
                              setIsOpenChangeLang(true);
                            }}
                          >
                            {t('pages.create_edit_course.translate_button')}
                          </Dropdown.Button>
                          <Dropdown.Button
                            onClick={() => {
                              setIsOpenExportModal(true);
                            }}
                          >
                            {t('pages.create_edit_course.export')}
                          </Dropdown.Button>

                          {publication?.publicationStatus === 'disabled' ? (
                            <Dropdown.Button
                              onClick={() => isOwner && handleActivate()}
                              disabled={!isOwner}
                              className={`group relative ${
                                !isOwner && 'text-grey-04'
                              }`}
                            >
                              {t('pages.create_edit_course.activate_button')}
                              {!isOwner && (
                                <div className="absolute right-0 top-[110%] z-[10] hidden w-[300px] rounded-lg bg-white p-3 text-xs text-grey-08 shadow-[0_0_5px_-1px_rgba(0,0,0,0.3)] group-hover:flex">
                                  {t(
                                    'pages.create_edit_course.message_not_owner'
                                  )}
                                </div>
                              )}
                            </Dropdown.Button>
                          ) : (
                            <Dropdown.Button
                              onClick={() => isOwner && handleDeactivate()}
                              disabled={!isOwner}
                              className={`group relative ${
                                !isOwner && 'text-grey-04'
                              }`}
                            >
                              {t('pages.create_edit_course.deactivate_button')}
                              {!isOwner && (
                                <div className="absolute right-0 top-[110%] z-[10] hidden w-[300px] rounded-lg bg-white p-3 text-xs text-grey-08 shadow-[0_0_5px_-1px_rgba(0,0,0,0.3)] group-hover:flex">
                                  {t(
                                    'pages.create_edit_course.message_not_owner'
                                  )}
                                </div>
                              )}
                            </Dropdown.Button>
                          )}

                          <Dropdown.Button
                            onClick={() => isOwner && handleDelete()}
                            disabled={!isOwner}
                            className={`group relative text-[#FC6232] ${
                              !isOwner && ' text-grey-04'
                            }`}
                          >
                            {t('pages.create_edit_course.delete_button')}
                            {!isOwner && (
                              <div className="absolute right-0 top-[110%] z-[10] hidden w-[300px] rounded-lg bg-white p-3 text-xs text-grey-08 shadow-[0_0_5px_-1px_rgba(0,0,0,0.3)] group-hover:flex">
                                {t(
                                  'pages.create_edit_course.message_not_owner'
                                )}
                              </div>
                            )}
                          </Dropdown.Button>
                          {/* </Dropdown.Actions> */}
                        </Dropdown.Content>
                      </Dropdown>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="mt-4 flex items-center border-t border-solid border-grey-02 px-3 pt-3">
                <div className="text-base font-medium line-clamp-2">
                  {publication.name}
                </div>

                <div className="mx-2">
                  {publication.publicationStatus === 'published' ? (
                    <Tag
                      title={publication.publicationStatus}
                      size="sm"
                      variant="primary"
                    />
                  ) : (
                    <Tag
                      title={publication.publicationStatus || ''}
                      size="sm"
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </header>
      )}
    </div>
  );
};

export default EditCourseHeader;
