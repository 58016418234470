import { useState, useContext, useReducer, useEffect } from 'react';
import { useGet } from '@/query/fetchHooks';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '@/components/Contexts/AuthContext';
import {
  InputSelect,
  Label,
  Button,
  InputDate,
  Switch,
  IconButton,
} from '@teo/components';
import { CrossFilled } from '@teo/components/icons';
import { InputSelectOption } from '@/models/organisation.interface';
import { PaginationMultiSelect } from '@/components/PaginationMultiSelect';
import DownloadReportModal from '../DownloadReportModal';
import ReportCoursesDataTable from '@/components/ReportDataTable/ReportCoursesDataTable';
import { languages } from '@/constants/languages';

const reportTypeList = [
  {
    value: 'courseOverview',
    label: 'report.courseOverview',
  },
  {
    value: 'overviewCourse',
    label: 'pages.report.course_overview',
  },
  {
    value: 'courseCsv',
    label: 'report.courseCsv',
  },
  {
    value: 'studentOverview',
    label: 'report.student_overview',
  },
  {
    value: 'coursedetailsCsv',
    label: 'report.coursedetailsCsv',
  },
  {
    value: 'certificate',
    label: 'report.certificate',
  },
];

type reportDownload = {
  usersReport: any;
  fromDate: any;
  untilDate: any;
  fileType: any;
  reportEmail: boolean;
};

function CoursesReport({ courseType }: any) {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);

  const [fromDate, setFromDate] = useState<Date | undefined>();
  const [untilDate, setUntilDate] = useState<Date | undefined>();
  const [selectedCourses, setSelectedCourses] = useState<any[]>([]);
  const [isOpenDownloadtModal, setIsOpenDownloadtModal] = useState(false);
  const [allCourses, setAllCourses] = useState(false);
  const [disabledAll, setDisabledAll] = useState(true);
  const [selectType, setSelectType] = useState(reportTypeList[0].value);

  const reportState = {
    coursesReport: [],
    fromDate,
    untilDate,
    reportType: {
      label: reportTypeList[0].label,
      value: reportTypeList[0].value,
    },
    reportEmail: true,
    lang: languages?.find((option) => option.value === auth?.user?.lang),
  };

  const [stateReport, updateStateReport] = useReducer(
    (state: reportDownload, updates: any) => ({ ...state, ...updates }),
    reportState
  );

  const handleAddCourses = async (newValue: any) => {
    const addCourse = [...stateReport.coursesReport];
    for (const obj2 of newValue) {
      if (obj2?.id) {
        const existingCourses = stateReport.coursesReport.find(
          (obj1: any) => obj1?.id && obj1?.id === obj2?.id
        );
        if (!existingCourses) {
          addCourse.push(obj2);
        }
      }
    }
    updateStateReport({ coursesReport: addCourse });
    setSelectedCourses([]);
  };

  const handleRemove = (id: any) => {
    const removeCourse = stateReport.coursesReport?.filter(
      (item: any) => item.id !== id
    );
    updateStateReport({ coursesReport: removeCourse });
  };

  useEffect(() => {
    if (
      selectType == 'courseOverview' ||
      selectType == 'overviewCourse' ||
      selectType == 'studentOverview' ||
      selectType == 'certificate'
    ) {
      setDisabledAll(true);
      setAllCourses(false);
    } else {
      setDisabledAll(false);
    }
  }, [selectType]);

  return (
    <div className="gap- flex flex-col">
      <div className="mt-6 rounded-lg border border-grey-02 p-6">
        <div className="flex max-w-xl flex-col">
          <p className="pb-2 text-base font-semibold text-black">
            {t('pages.report_page.teams_tab.title_1')}
          </p>
          <p className="text-base text-grey-06">
            {t(`pages.report_page.${courseType}_tab.subtitle_1`)}
          </p>
          <div className="mb-1 mt-4 min-w-full max-w-[300px] text-sm font-semibold text-black">
            <p className="mb-1">
              {t('pages.report_page.teams_tab.type_of_report')}
            </p>
            <div className="font-normal">
              <InputSelect<InputSelectOption>
                value={stateReport.reportType}
                onChange={(e: InputSelectOption) => {
                  setSelectType(e?.value);
                  updateStateReport({ reportType: e });
                }}
                options={reportTypeList}
              >
                {(options) => <span>{options.label && t(options.label)}</span>}
              </InputSelect>
            </div>
          </div>

          <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
            <div data-testid="reportEmail">
              <Switch
                checked={allCourses}
                disabled={disabledAll}
                onChange={(isCheck) => setAllCourses(isCheck)}
              />
            </div>
            <div>
              <h4 className="font-normal">
                {t(`pages.report_page.select_${courseType}`)}
              </h4>
            </div>
          </div>

          {!allCourses && (
            <div className="z-[1] mb-1 mt-4 text-sm font-semibold text-black">
              <p className="mb-1">{t(`pages.report_page.${courseType}`)}</p>
              <div className="flex">
                <span className="w-full">
                  <PaginationMultiSelect
                    id="teams-report"
                    value={selectedCourses}
                    onChange={(value: any) => {
                      setSelectedCourses(value);
                    }}
                    keyFn={(row: any) => row?.id}
                    fetch={useGet.bind(
                      null,
                      `/membercoursedetails?organisationId=${auth?.user?.activeOrganisationId}&courseType=${courseType}`
                    )}
                    defaultSort={[{ id: 'courseName', desc: false }]}
                    render={(row: any) => {
                      return row?.courseName ? row?.courseName : row?.name;
                    }}
                  />
                </span>
                <Button
                  className="ml-3 h-[36px] text-base font-medium"
                  variant="primary"
                  disabled={selectedCourses.length === 0}
                  onClick={async () => {
                    handleAddCourses(selectedCourses);
                  }}
                >
                  {t('button.add_btn')}
                </Button>
              </div>
              {stateReport?.coursesReport?.length > 0 && (
                <div className="mt-6">
                  {stateReport?.coursesReport?.map((course: any) => (
                    <div
                      key={course.id == null ? course.username : course.id}
                      className="mb-4 flex items-center justify-between"
                    >
                      <div className="flex items-center">
                        <div className="mr-3 w-16 min-w-16 sm:w-20 sm:min-w-20">
                          <img
                            src={
                              course?.courseImage ||
                              course?.image ||
                              '/images/courseFallback.png'
                            }
                            alt={course.name}
                            className="inline h-12 w-full rounded-lg object-cover sm:h-14"
                          />
                        </div>
                        {course?.courseName || course?.name}
                      </div>
                      <div data-testid="daleteItem" className="my-auto ml-6">
                        <IconButton
                          Icon={CrossFilled}
                          variant="ghost"
                          onClick={() => handleRemove(course.id)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          <div className="mt-3">
            <Label htmlFor="language">
              {t('pages.profile_account.language')}
            </Label>
            <div id="language">
              <InputSelect<InputSelectOption>
                value={stateReport.lang}
                onChange={async (language) => {
                  updateStateReport({ lang: language });
                }}
                options={languages}
              >
                {(options) => t(options.label)}
              </InputSelect>
            </div>
          </div>
          <div className="mt-7 flex flex-col gap-5 pr-2 sm:flex-row lg:pr-0">
            <div className="basis-1/2" data-testid="dateStart">
              <Label htmlFor="dateStart">
                {t('pages.report_page.teams_tab.from')}
              </Label>
              <InputDate
                id="dateStart"
                onChange={(date) => updateStateReport({ fromDate: date })}
                value={stateReport.fromDate}
                variant="date"
              />
            </div>
            <div className="basis-1/2" data-testid="dateEnd">
              <Label htmlFor="dateEnd">
                {t('pages.report_page.teams_tab.until')}
              </Label>
              <InputDate
                id="dateEnd"
                onChange={(date) => updateStateReport({ untilDate: date })}
                value={stateReport.untilDate}
                variant="date"
              />
            </div>
          </div>
          <div className="mt-5 flex flex-row items-center gap-4 rounded-lg border border-grey-02 p-4">
            <div data-testid="reportEmail">
              <Switch
                checked={stateReport.reportEmail}
                onChange={(isCheck) =>
                  updateStateReport({ reportEmail: isCheck })
                }
              />
            </div>
            <div>
              <h4 className="pb-2 font-normal">
                {t('pages.report_page.teams_tab.toggle_email_heading')}
              </h4>
              <p className="text-xs text-grey-05">
                {t('pages.report_page.teams_tab.toggle_email_text')}
              </p>
            </div>
          </div>
          <div className="flex pt-6">
            <Button
              onClick={() => setIsOpenDownloadtModal(true)}
              disabled={stateReport?.coursesReport?.length < 1 && !allCourses}
            >
              {t('pages.report_page.teams_tab.btn_download')}
            </Button>
          </div>
        </div>
      </div>

      <ReportCoursesDataTable
        reportTypeList={reportTypeList}
        courseType={courseType}
      />

      {isOpenDownloadtModal && (
        <DownloadReportModal
          report={'courses'}
          isOpenDownloadtModal={isOpenDownloadtModal}
          closeModal={() => setIsOpenDownloadtModal(false)}
          setIsOpenDownloadtModal={setIsOpenDownloadtModal}
          stateReport={stateReport}
          user={auth?.user}
          updateStateReport={updateStateReport}
          allSelect={allCourses ? undefined : true}
          courseType={courseType}
        />
      )}
    </div>
  );
}

export default CoursesReport;
