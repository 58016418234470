import { useState, Fragment, useContext, useEffect } from 'react';
import { useGet } from '@/query/fetchHooks';
import {
  InputText,
  ContentCard,
  Button,
  IconButton,
  FilterList,
  Spinner,
  Tag as ComponentTag,
} from '@teo/components';
import { Search, ChevronDown, ChevronUp } from '@teo/components/icons';
import { T } from '@/components/T';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { Userwithorganisationwithidps, Followedcourses } from '@/models';
import { useNavigate } from 'react-router-dom';
import { PaginationAnything } from '@/components/DataTable/PaginationAnything';
import { HintsContext } from '@/components/Contexts/HintsContext';
import Hint from '@/components/Hints/Hints';

export interface Filter {
  label: string;
  value: string;
}

const LearnCourseOverview = ({
  courseType,
  title,
}: {
  courseType: string;
  title: string;
}) => {
  const { t } = useTranslation();
  const { hints } = useContext(HintsContext);
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState([{ id: 'createdAt', desc: true }]);
  const [loadCourses, setLoadCourses] = useState<any>(true);

  const [selected, setSelected] = useState<Filter[]>([
    {
      label: t('pages.learn_courses.active'),
      value: 'active',
    },
    {
      label: t('pages.learn_courses.new'),
      value: 'new',
    },
  ]);
  const [filterStatus, setFilterStatus] = useState(courseType);
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;
  const basePath = window.location.pathname;
  const coursePath = window.location.pathname;

  function statusCourseObj(str: string) {
    switch (str) {
      case 'active':
        return { value: 'status=active', courseStatus: 'courseStatus=active' };
      case 'new':
        return {
          value: 'status=pending,OR,status=null',
          courseStatus: 'courseStatus=active',
        };
      case 'done':
        return {
          value: 'status=done,OR,status=pendingcorrection',
          courseStatus: 'courseStatus=disabled',
        };
      default:
        return null;
    }
  }

  useEffect(() => {
    if (selected.length == 0) {
      setFilterStatus(courseType);
    }
    if (selected.length == 1) {
      const selectFilter = statusCourseObj(selected[0].value);
      setFilterStatus(
        selected[0].value != 'done'
          ? `${courseType}&${selectFilter?.courseStatus}&${selectFilter?.value}`
          : `${courseType}&${selectFilter?.courseStatus},OR,${selectFilter?.value}`
      );
    }
    if (selected.length > 1) {
      const selectFilterArr = selected?.map((el) => statusCourseObj(el?.value));
      const select = selectFilterArr?.map((el) => el?.value).join(',OR,');
      const arrCS = selectFilterArr?.map((el) => el?.courseStatus);
      const uniqueArr = arrCS?.filter(
        (item, index) => arrCS.indexOf(item) === index
      );
      let crStatus;
      if (uniqueArr.length > 1) {
        crStatus = uniqueArr?.join(',OR,');
      } else {
        crStatus = uniqueArr;
      }
      setFilterStatus(`${courseType}&${crStatus}&${select}`);
    }
  }, [selected]);

  const FILTERS = [
    {
      label: t('pages.learn_courses.active'),
      value: 'active',
    },
    {
      label: t('pages.learn_courses.new'),
      value: 'new',
    },
    {
      label: t('pages.learn_courses.completed'),
      value: 'done',
    },
  ];

  function convertToTime(number: number) {
    const hours = Math.floor(number / 3600);
    const minutes = Math.round((number / 3600 - hours) * 60);
    return (
      hours +
      `${t('pages.learn_index.hour')} ` +
      minutes +
      `${t('pages.learn_index.minute')} `
    );
  }

  const createMarkup = (text: any) => {
    if (text) return { __html: text };
  };

  if (courseType === 'instruction' && FILTERS) {
    FILTERS.pop();
  }

  return (
    <div className="w-full max-w-6xl">
      <div className="my-8">
        <h1 className="text-xl font-semibold text-black">
          <T>{title}</T>
        </h1>
      </div>

      <Fragment>
        <div
          className={`relative mb-8 sm:w-2/3 md:w-1/2 ${
            hints?.id == `hint_09` && 'active-hint-parent !z-[100]'
          }`}
        >
          <InputText
            value={filter}
            onChange={(ev) => setFilter(ev.target.value)}
            Icon={Search}
            placeholder={t('search.course')}
          />
        </div>
        <div className="mb-8 flex flex-col justify-between gap-2.5 md:flex-row md:gap-0 lg:flex-row lg:gap-0">
          <div
            className={`relative flex items-center ${
              hints?.id == `hint_08` && 'active-hint-parent'
            }`}
          >
            <Hint
              key={`hint_08`}
              id={`hint_08`}
              leftDots={'-left-2 top-[9px]'}
              title={t(`hint.title_08`)}
              order={8}
              style={{
                left: '-10px',
                top: '65px',
              }}
            />
            <FilterList
              filters={FILTERS}
              onSelect={setSelected}
              selected={selected}
            />
          </div>
          <div className="flex items-center">
            {/* todo: more filters needed */}
            {/* <Button size="sm" variant="outline" className="mr-2">
              {t('pages.create_courses.more_filters')}
            </Button> */}
            <Button
              className="flex items-center"
              variant="ghost"
              size="sm"
              onClick={() =>
                sort[0].desc
                  ? setSort([{ id: 'createdAt', desc: false }])
                  : setSort([{ id: 'createdAt', desc: true }])
              }
            >
              <div>{t('pages.create_courses.latest')}</div>
              <IconButton
                Icon={sort[0].desc ? ChevronUp : ChevronDown}
                variant="ghost"
              />
            </Button>
          </div>
        </div>
        {loadCourses && (
          <div className="mx-auto flex justify-center py-20 px-10">
            <Spinner
              ariaLabel="Loading spinner"
              className="!h-10 !w-10 border-grey-05"
            />
          </div>
        )}
        <PaginationAnything
          className={`mt-3 flex w-full flex-wrap gap-3 ${
            loadCourses === true && ' hidden '
          }`}
          fetch={useGet.bind(null, '/mycourses', {
            scope: 'nothidden',
            userId: authUser?.id,
            organisationId:
              authUser?.activeOrganisationId + ',OR,organisationId=null',
            courseType: filterStatus,
            onSuccess: (result: any) => {
              setLoadCourses(false);
            },
          })}
          empty={{
            template: 2,
            loadContent: loadCourses,
            text: t('empty_tenplate.course_list'),
            // btn_text: t('empty_tenplate.btn_creat_course'),
            // btnClick: () => setIsOpenTypeModal(true),
          }}
          defaultSort={sort}
          pageSize={12}
          filter={filter}
          render={(course: Followedcourses) => (
            <div
              className="relative max-w-[calc(50%_-_6px)] basis-1/2 gap-4 sm:max-w-[calc(33.3333%_-_8px)] sm:basis-1/3 lg:max-w-[calc(25%_-_9px)] lg:basis-1/4"
              key={course.id}
            >
              <ContentCard
                customStyles="text-left whitespace-break-spaces overflow-y-hidden text-ellipsis"
                chapters={course?.nrOfLessons ? course.nrOfLessons : 0}
                duration={
                  course?.totalEstimatedTime
                    ? convertToTime(Math.abs(course?.totalEstimatedTime))
                    : ''
                }
                title={course?.courseName || course?.name || ''}
                onClick={() => navigate(`${coursePath}/${course.id}`)}
                Image={() => (
                  <ContentCard.Image
                    src={
                      course?.courseImage ||
                      course?.image ||
                      '/assets/images/opleidingen.jpg'
                    }
                    alt="Electrical dial"
                  />
                )}
                Tag={() => (
                  // <ContentCard.Tag title={t(`status.${course.status}`) || ''} />
                  <div className="mb-3 text-left">
                    <ComponentTag title={course.status || ''} />
                  </div>
                )}
                Actions={() => (
                  <>
                    <div className="absolute top-0">
                      <img
                        key={course.id}
                        src={`/images/flags/${course.lang}.svg`}
                        alt="courseImage"
                        className="
                          mt-2
                          ml-2
                          h-[25px]
                          w-[25px] rounded-[50%]
                          bg-tint-red
                          object-cover
                          "
                      />
                    </div>
                  </>
                )}
                authors={[]}
                /*
                authors={[
                  {
                    id: (course?.authorId as number)?.toString(),
                    name: course?.username as string
                  }
                ]}
                */
              >
                <div className="mb-3 max-h-[60px] max-w-[264px] overflow-hidden text-ellipsis text-left text-sm font-normal text-[#6d6d72]">
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      course?.courseDescription || course?.description || ''
                    )}
                  />
                </div>
              </ContentCard>
            </div>
          )}
        />
        {/*
        <div className="mt-3 flex w-full max-w-6xl flex-wrap gap-3">
          {data?.data &&
            data?.data?.map((course: Followedcourses) => (
              <div className="relative" key={course.id}>

              </div>
            ))}
        </div>
        */}
      </Fragment>
    </div>
  );
};

export default LearnCourseOverview;
